<div class="container container-boxed">
  <div class="publications--filters">
    <form (submit)="onSearchSubmit()" [formGroup]="searchForm" class="form form-horizontal">
      <div class="form-control">
        <input type="text" [formControlName]="'query'" placeholder="Suche">
      </div>
      <div class="form-control">
        <button class="button button-default">Suche</button>
      </div>
    </form>
  </div>
</div>

<div class="publications--list">
  <div class="container container-boxed container">
    <ol class="list-numbered" *ngIf="items.length">
      <li *ngFor="let item of items">
        <h3>{{item.title}}</h3>
        <p [innerHtml]="item.text"></p>
      </li>
    </ol>
  </div>
</div>
