export const environment = {
    production: false,
    api: {
        url: 'http://telemann-api.server018.com:8080/api'
    },
    gallery: {
        apiKey: '6A351E2C-C50B-4DCA-8102-C451576002CA'
    },
    cdn: {
        url: 'http://telemann-api.server018.com:8080/uploads'
    }
};
