<div class="events-list" [ngClass]="getClasses()" *ngIf="events">
  <ng-container *ngFor="let event of events">
    <article [ngClass]="getEventStyle(event)">
      <div class="event-image">
        <div class="image-holder" [style.background-image]="getEventImageUrl(event)">
        </div>
        <div class="event-meta">
          <div class="event-date">{{event.startTime | date:'EEEE, dd. MMMM | HH.mm'}} Uhr</div>
        </div>
      </div>
      <div class="event-content">
        <h3>
          <a [routerLink]="['/', 'event', event.slug]">
            {{ getEventDisplayName(event) }}
          </a>
        </h3>
        <p>
          <strong [innerHtml]="event.subtitle"></strong>
        </p>
        <div class="event-date">{{event.startTime | date:'EEEE, dd. MMMM | HH.mm'}} Uhr</div>
        <p class="event-artists" [innerHtml]="getEventArtists(event)"></p>
        <p>{{event.location}}</p>
      </div>
      <div class="event-buttons">
        <a [routerLink]="['/', 'event', event.slug]" class="button button-white">Details</a></div>
    </article>
  </ng-container>
</div>
