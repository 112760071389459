import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Text} from "./types/text";

@Component({
  selector: 'app-widget-text',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.less']
})
export class TextComponent {
  @Input() widget!: Text;

  public getClasses(): string[] {
    return [
      `text-${this.widget.data.textAlign}`
    ];
  }
}
