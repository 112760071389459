import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShortArticle} from "./types/short-article";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-widget-short-article',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './short-article.component.html',
  styleUrls: ['./short-article.component.less']
})
export class ShortArticleComponent {
  @Input() widget!: ShortArticle;

  public getStyles(): object {
    return {
      backgroundImage: `url(${environment.cdn.url}/cdn/${this.widget.data.image})`
    }
  }

}
