import {EventEmitter, Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private audioStarted: Subject<boolean> = new Subject<boolean>();

  public sendAudioStartedEvent(): void {
    this.audioStarted.next(true);
  }

  public getAudioStartedEvent(): Observable<boolean> {
    return this.audioStarted.asObservable();
  }
}
