import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EventsService} from "../../../events/events.service";
import {EventCategoryListItem} from "../../../events/types/event-category-list-item";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {CategoryFilterItem} from "./types/category-filter-item";
import {Event} from "../../../events/types/event";
import {RouterLink} from "@angular/router";
import {EventFilters} from "./types/event-filters";
import {EventsFilterResponse} from "../../../events/types/events-filter-response";
import {EventsCalendar} from "./types/events-calendar";

@Component({
  selector: 'app-widget-events-calendar',
  standalone: true,
  imports: [CommonModule, SlickCarouselModule, RouterLink],
  templateUrl: './events-calendar.component.html',
  styleUrls: [
    './events-calendar.component.less',
    '../../../../styles/events/_events-list.less'
  ]
})
export class EventsCalendarComponent implements OnInit {

  @Input() widget !: EventsCalendar;

  public slideConfig = {
    infinite: false,
    slidesToShow: 10,
    slidesToScroll: 3,
    responsive: [{
      breakpoint: 1024, settings: {
        slidesToShow: 6,
      }
    }, {
      breakpoint: 600, settings: {
        slidesToShow: 4, slidesToScroll: 2
      }
    }, {
      breakpoint: 480, settings: {
        slidesToShow: 3, slidesToScroll: 2
      }
    }]
  };
  public categories!: CategoryFilterItem[];
  public events!: Event[];
  public eventDates!: string[];
  public viewMode: 'list' | 'grid' = 'list';
  public selectedDay: string | null = null;
  public selectedYear: number | null = null;

  private eventFilters: EventFilters = {
    categoryIds: [],
    dateFrom: '2023-01-01',
    dateTo: '2023-12-31 23:59:59'
  };

  public constructor(
    private eventsService: EventsService
  ) {
  }

  ngOnInit(): void {
    this.selectedYear = (new Date()).getFullYear();

    this.eventsService.getCategoriesList()
      .subscribe(
        (categories: EventCategoryListItem[]) => {
          this.categories = categories.map((c) => {
            return {
              category: c,
              selected: false,
            }
          })
        }
      );

    this.loadEvents();
  }

  private loadEvents(): void {
    this.eventsService.getEvents(
      this.eventFilters.categoryIds,
      this.eventFilters.dateFrom,
      this.eventFilters.dateTo,
    )
      .subscribe(
        (response: EventsFilterResponse) => {
          this.events = response.events;
          this.eventDates = response.days;
        }
      );
  }

  public getCategoryButtonClasses(category: EventCategoryListItem): string[] {
    return [
      `button-event-category-style--${category.style}`
    ]
  }

  public getEventArtists(event: Event): string {
    return event.artists.map(a => a.name).join(', ');
  }

  public getEventStyle(event: Event): string[] {
    return [
      `event-style--${event.style}`
    ];
  }

  public getEventImageUrl(event: Event): string | null {
    if (!event.imageUrl) {
      return null;
    }

    return `url(${event.imageUrl})`;
  }

  public getEventDisplayName(event: Event): string {
    if (!event.guid) {
      return event.name;
    }

    return `${event.guid} | ${event.name}`
  }

  public filterBySelectedDate(event: Event): boolean {
    return this.selectedDay === null || event.startTime.startsWith(this.selectedDay);
  }

  public onClickCategoryFilter(category: CategoryFilterItem): void {
    category.selected = !category.selected;
    this.eventFilters.categoryIds = this.categories.filter(c => c.selected).map(c => c.category.id);
    this.loadEvents();
  }

  public onClickYearFilter(year: number) {
    this.eventFilters.dateFrom = `${year}-01-01`;
    this.eventFilters.dateTo = `${year}-12-31 23:59:59`;
    this.selectedDay = null;
    this.selectedYear = year;
    this.loadEvents()
  }

  public onClickEventDay(day: string): void {
    if (this.selectedDay === day) {
      this.selectedDay = null;
      return;
    }

    this.selectedDay = day;
  }

  public setViewMode(value: 'list' | 'grid'): void {
    this.viewMode = value;
  }
}
