import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {DownloadLink} from "./types/download-link";

@Component({
  selector: 'app-widget-download-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.less']
})
export class DownloadLinkComponent {
  @Input() widget!: DownloadLink;
}
