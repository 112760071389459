import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PopupMenuComponent} from "../../menu/popup-menu/popup-menu.component";
import {TopMenuComponent} from "../../menu/top-menu/top-menu.component";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, PopupMenuComponent, TopMenuComponent, RouterLink],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent {

}
