import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Layout} from "../../../page/types/layout";
import {RowComponent} from "../row/row.component";
import {AccordionComponent} from "../accordion/accordion.component";
import {PagedContentComponent} from "../paged-content/paged-content.component";

@Component({
  standalone: true,
  selector: 'app-layout',
  imports: [CommonModule, RowComponent, AccordionComponent, PagedContentComponent],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})
export class LayoutComponent implements OnInit {
  @Input() layout!: Layout;

  ngOnInit(): void {
  }
}
