import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Blog} from "./types/blog";
import {Article} from "./types/article";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-widget-blog',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.less']
})
export class BlogComponent {
  @Input() widget!: Blog;

  public getStyles(article: Article): object {
    return {
      backgroundImage: `url(assets/images/articles/${article.image})`
    }
  }
}
