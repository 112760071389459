import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSegment} from '@angular/router';
import {menuResolver} from "./menu/menu.resolver";
import {PageComponent} from "./page/page.component";
import {pageResolver} from "./page/page.resolver";
import {EventComponent} from "./events/event/event.component";
import {eventResolver} from "./events/event.resolver";

function slugMatcher(segments: UrlSegment[]) {
  if (segments.length > 0) {
    return {
      consumed: segments,
      posParams: {
        slug: new UrlSegment(segments.join('/'), {})
      }
    };
  }
  return null;
}

const routes: Routes = [
  {
    path: '',
    resolve: {
      menu: menuResolver,
    },
    children: [
      {
        path: '',
        component: PageComponent,
        resolve: {
          page: pageResolver,
        }
      },
      {
        path: 'event/:slug',
        component: EventComponent,
        resolve: {
          event: eventResolver,
        }
      },
      {
        matcher: slugMatcher,
        component: PageComponent,
        resolve: {
          page: pageResolver,
        }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
