import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Image} from "./types/image";
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-widget-image',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.less']
})
export class ImageComponent {
  @Input() widget!: Image;

  public getClasses(): string[] {
    return [
      `image-${this.widget.data.style}`,
    ];
  }

  public getImageUrl(): string {
    return `${environment.cdn.url}/cdn/${this.widget.data.imageUrl}`;
  }
}
