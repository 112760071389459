<ng-container *ngIf="menu">
  <menu class="popup-menu">
    <div class="popup-menu--button" [class.active]="menuIsActive" (click)="onPopupMenuClick()">
      <figure>
        <span></span>
        <span></span>
        <span></span>
      </figure>
    </div>

    <div *ngIf="menuIsActive" class="popup-menu--content">
      <div class="container container-boxed">
        <ul class="popup-menu--menu">
          <ng-container *ngFor="let item of menu.items">
            <app-menu-item [item]="item" [customClass]="'popup-menu-item'"></app-menu-item>
          </ng-container>
          <ng-container *ngFor="let item of topMenu.items">
            <app-menu-item [item]="item" [customClass]="'popup-menu-item mobile-only'"></app-menu-item>
          </ng-container>
        </ul>
      </div>
    </div>
  </menu>
</ng-container>
