import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from "@angular/router";
import {MenuItem} from "../types/menu-item";

@Component({
  selector: 'app-menu-item',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.less'],
})
export class MenuItemComponent {
  @Input() customClass!: string;
  @Input() item!: MenuItem;

  public getUrl(): string {
    switch (this.item.type) {
      case 'url':
        return this.item.data.url;
      case 'page':
      default:
        return 'homepage';
    }
  }
}
