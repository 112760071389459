import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Gallery} from "./types/gallery";
import {LightgalleryModule} from "lightgallery/angular";
import {ImageItem} from "./types/gallery-data";
import lgZoom from 'lightgallery/plugins/zoom';
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-widget-gallery',
    standalone: true,
    imports: [CommonModule, LightgalleryModule],
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.less']
})
export class GalleryComponent {
    @Input() widget!: Gallery;

    public settings = {
        counter: false,
        plugins: [lgZoom],
        licenseKey: environment.gallery.apiKey,
    };

    public getWrapperClasses(): string[] {
        return [
            `gallery-widget--columns-${this.widget.data.itemsPerRow}`
        ]
    }

    public getSrcUrl(image: ImageItem): string {
        return `${environment.cdn.url}/cdn/${image.url}`;
    }

    public getThumbnailUrl(image: ImageItem): string {
        return `${environment.cdn.url}/cdn/thumbnails/${image.thumbnail}`;
    }
}
