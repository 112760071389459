import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {List} from "./types/list";

@Component({
  selector: 'app-widget-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class ListComponent {
  @Input() widget!: List;
}
