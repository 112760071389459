<div class="blog-widget">
  <article *ngFor="let article of widget.data.articles" class="short-article-widget">
    <div class="short-article--image" [ngStyle]="getStyles(article)"></div>
    <div class="short-article--content">
      <h4>{{article.title}}</h4>
      <div [innerHtml]="article.text"></div>
      <div class="article-button text-right">
        <a class="button button-default" [routerLink]="article.url">weiterlesen</a>
      </div>
    </div>
  </article>
</div>
