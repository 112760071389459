<div class="social-icons">
  <ul>
    <li>
      <a href="">
        <img [src]="'assets/images/social/facebook.svg'" alt="Facebook">
      </a>
    </li>
    <li>
      <a href="">
        <img [src]="'assets/images/social/instagram.svg'" alt="Instagram">
      </a>
    </li>
    <li>
      <a href="">
        <img [src]="'assets/images/social/twitter.svg'" alt="Twitter">
      </a>
    </li>
  </ul>
</div>
