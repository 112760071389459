<div class="events-with-filters">
  <div class="container container-boxed">

    <div class="events-with-filters--header">
      <div class="event-filters">
        <div class="event-filters--season">
          <div class="season-picker">
            <label *ngFor="let year of widget.data.years">
              <input [checked]="year === selectedYear" name="season" type="radio" [value]="year">
              <span (click)="onClickYearFilter(year)">{{ year }}</span>
            </label>
          </div>
        </div>

        <div class="event-filters--view-mode">
          <h5>Ansicht:</h5>
          <div class="view-mode-picker">
            <label>
              <input checked="checked" type="radio" value="list" name="view-mode" (click)="setViewMode('list')">
              <span>
                <img src="assets/images/icons/icon-list.svg" alt="List mode">
              </span>
            </label>
            <label>
              <input value="grid" type="radio" name="view-mode" (click)="setViewMode('grid')">
              <span>
                <img src="assets/images/icons/icon-grid.svg" alt="Grid mode">
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="event-filters--day-picker" *ngIf="eventDates">
        <div class="event-days">
          <ngx-slick-carousel class="carousel" [config]="slideConfig">
            <ng-container *ngFor="let day of eventDates">
              <div ngxSlickItem class="event-day" [class.selected]="day === selectedDay" (click)="onClickEventDay(day)">
                <div class="event-day--content">
                  <span>{{day | date:'EEE'}}</span>
                  <time>{{day | date:'dd.MM.'}}</time>
                </div>
              </div>
            </ng-container>
          </ngx-slick-carousel>
        </div>
      </div>

      <div class="event-filters--categories">
        <h1>Kalender</h1>
        <h4>Filter:</h4>
        <div *ngIf="categories" class="event-filters--categories-list">
          <a *ngFor="let category of categories"
             (click)="onClickCategoryFilter(category)"
             [class.active]="category.selected"
             [ngClass]="getCategoryButtonClasses(category.category)"
             class="button button-event-category">
            {{ category.category.name }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="events-list-container">
    <div class="container container-boxed">
      <div class="events-list" *ngIf="events" [class.list-view]="viewMode === 'list'"
           [class.grid-view]="viewMode === 'grid'">
        <ng-container *ngFor="let event of events">
          <article *ngIf="filterBySelectedDate(event)" [ngClass]="getEventStyle(event)">
            <div class="event-image">
              <div class="image-holder" [style.background-image]="getEventImageUrl(event)">
              </div>
              <div class="event-meta">
                <div class="event-date">{{event.startTime | date:'EEEE, dd. MMMM | HH.mm'}} Uhr</div>
              </div>
            </div>
            <div class="event-content">
              <h3>
                <a [routerLink]="['/', 'event', event.slug]">
                  {{ getEventDisplayName(event) }}
                </a>
              </h3>
              <p>
                <strong [innerHtml]="event.subtitle"></strong>
              </p>
              <div class="event-date">{{event.startTime | date:'EEEE, dd. MMMM | HH.mm'}} Uhr</div>
              <p class="event-artists" [innerHtml]="getEventArtists(event)"></p>
              <p>{{event.location}}</p>
            </div>
            <div class="event-buttons">
              <a [routerLink]="['/', 'event', event.slug]" class="button button-white">Details</a></div>
          </article>
        </ng-container>
      </div>
    </div>
  </div>
</div>
