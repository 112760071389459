import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MenuComponent} from "./menu/menu.component";
import {TopMenuComponent} from "./menu/top-menu/top-menu.component";
import {HttpClientModule} from "@angular/common/http";
import {FooterMenuComponent} from "./menu/footer-menu/footer-menu.component";
import {PopupMenuComponent} from "./menu/popup-menu/popup-menu.component";
import {CommonModule} from "@angular/common";
import {HeaderComponent} from "./layout/header/header.component";
import {FooterComponent} from "./layout/footer/footer.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DynamicChildLoaderDirective} from './directives/dynamic-child-loader.directive';

@NgModule({
  declarations: [
    AppComponent,
    DynamicChildLoaderDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MenuComponent,
    TopMenuComponent,
    HttpClientModule,
    FooterMenuComponent,
    PopupMenuComponent,
    CommonModule,
    HeaderComponent,
    FooterComponent,
    BrowserAnimationsModule,
  ],
  providers: [

  ],
  exports: [
    DynamicChildLoaderDirective,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
