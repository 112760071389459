import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PromoBlock} from "./types/promo-block";
import {RouterLink} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-widget-promo-block',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './promo-block.component.html',
  styleUrls: ['./promo-block.component.less']
})
export class PromoBlockComponent {
  @Input() widget!: PromoBlock;

  public getClasses(): string[] {
    let classes: string[] = []

    if (this.widget.data.image === null) {
      classes.push('promo-block--no-image');
    }

    return classes;
  }

  public getImageUrl(): string {
    return `${environment.cdn.url}/cdn/${this.widget.data.image}`;
  }

  public getImageStyles(): object {
    return {
      backgroundImage: `url(${this.getImageUrl()})`
    }
  }
}
