<div class="download-link-widget">
  <div class="download-link--wrapper">
    <div class="download-link--file">
      <div class="file-icon">

      </div>
      <div class="file-name">
        {{ widget.data.file }}
      </div>
    </div>
    <div class="download-link--content">
      <h6>{{ widget.data.title }}</h6>
      <p>{{ widget.data.description }}</p>
    </div>
  </div>
</div>
