import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Person} from "./types/person";
import {RouterLink} from "@angular/router";
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-widget-person',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.less']
})
export class PersonComponent {
  @Input() widget!: Person;

  public getImageUrl(): string {
    return `${environment.cdn.url}/cdn/${this.widget.data.image}`;
  }

  public getClasses(): string[] {
    let classes = [
      `person-widget--background-${this.widget.data.style}`,
      `person-widget--layout-${this.widget.data.layout}`
    ];

    if (this.widget.data.image === null) {
      classes.push('person-widget--no-image');
    }

    if (this.widget.data.textAlign) {
      classes.push(`text-${this.widget.data.textAlign}`)
    }

    return classes;
  }
}
