import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Page} from "./types/page";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PageService {
  constructor(private http: HttpClient) {
  }

  public getPageBySlug(slug: string): Observable<Page> {
    return this.http.get<Page>(`${environment.api.url}/ui/v1/page/${slug}`);
  }
}
