import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterMenuComponent} from "../../menu/footer-menu/footer-menu.component";
import {SocialComponent} from "../../social/social.component";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, FooterMenuComponent, SocialComponent],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent {

}
