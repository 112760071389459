import {Component, Input, OnInit} from '@angular/core';
import {CommonModule, formatDate} from '@angular/common';
import {EventsService} from "../../../events/events.service";
import {EventsFilterResponse} from "../../../events/types/events-filter-response";
import {RouterLink} from "@angular/router";
import {Event} from "../../../events/types/event";
import {UpcomingEvents} from "./types/upcoming-events";

@Component({
  selector: 'app-widget-upcoming-events',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './upcoming-events.component.html',
  styleUrls: [
    './upcoming-events.component.less',
    '../../../../styles/events/_events-list.less'
  ]
})
export class UpcomingEventsComponent implements OnInit {
  @Input() widget!: UpcomingEvents;
  public events!: Event[];

  public constructor(
    private eventsService: EventsService
  ) {
  }

  ngOnInit(): void {
    let date = formatDate(new Date(), 'yyyy-MM-dd 00:00:00', 'en');
    this.eventsService.getEvents(
      this.widget.data.filters?.categories,
      date,
      undefined,
      this.widget.data.limit,
    )
      .subscribe(
        (response: EventsFilterResponse) => {
          this.events = response.events;
        }
      );
  }

  public getClasses(): string[] {
    return [
      `${this.widget.data.view}-view`
    ];
  }

  getEventArtists(event: Event): string {
    return event.artists.map(a => a.name).join(', ');
  }

  getEventStyle(event: Event): string[] {
    return [
      `event-style--${event.style}`
    ];
  }

  getEventImageUrl(event: Event): string | null {
    if (!event.imageUrl) {
      return null;
    }

    return `url(${event.imageUrl})`;
  }

  public getEventDisplayName(event: Event): string {
    if (!event.guid) {
      return event.name;
    }

    return `${event.guid} | ${event.name}`
  }
}
