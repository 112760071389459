<div class="link-block-widget" [ngClass]="getClasses()">
  <div class="link-block-widget--inner-content">
    <a [routerLink]="getUrl()">
      <article>
        <div *ngIf="widget.data.image" class="link-block-image">
          <img [src]="getImageUrl()" [alt]="widget.data.title">
        </div>

        <div class="link-block-content">
          <h4>
            {{widget.data.title}}
            <a *ngIf="widget.data.fileUrl" [href]="getFileUrl()">
              <img src="assets/images/icons/icon-download.svg" alt="Download">
            </a>
          </h4>
          <p *ngIf="widget.data.description">
            {{widget.data.description}} {{widget.data.fileUrl}}
          </p>
        </div>
      </article>
    </a>
  </div>
</div>
