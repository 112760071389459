import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Publications} from "./types/publications";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Publication} from "./types/publication";

@Component({
  selector: 'app-widget-publications',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './publications.component.html',
  styleUrls: [
    './publications.component.less',
    '../../../../styles/form/form.less',
  ]
})
export class PublicationsComponent implements OnInit {
  @Input() widget!: Publications;
  public searchForm: FormGroup;
  public items!: Publication[];

  public constructor() {
    this.searchForm = new FormGroup({
      query: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.items = this.widget.data.items;
  }

  public onSearchSubmit(): void {
    let query = this.searchForm.getRawValue().query.toLowerCase();

    if (!query) {
      this.items = this.widget.data.items;
      return;
    }

    this.items = this.widget.data.items.filter(
      (item: Publication) => item.title.toLowerCase().includes(query) || item.text.toLowerCase().includes(query)
    )
  }
}
