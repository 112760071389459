import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {map, Observable} from "rxjs";
import {Event} from "../types/event";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-event',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.less']
})
export class EventComponent implements OnInit {
  public event$!: Observable<Event>;

  public constructor(
    private route: ActivatedRoute,
    private titleService: Title,
  ) {
  }

  ngOnInit(): void {
    this.event$ = this.route.data.pipe(
      map(
        data => data['event']
      )
    );

    this.event$.subscribe(event => {
      this.titleService.setTitle(event.name);
    })
  }

  public getEventPrices(event: Event): string {
    if (!event.priceMin && !event.priceMax) {
      return '';
    }

    let prices: string = '';
    if (event.priceMin) {
      prices += event.priceMin.toString();

      if (event.priceMax) {
        prices += ` - `
      }
    }

    if (event.priceMax) {
      prices += event.priceMax.toString();
    }

    prices += ' &euro;';

    return prices;
  }

  public getHeaderStyle(event: Event): object {
    let styles: any = {};

    if (event.imageUrl) {
      styles.backgroundImage = `url(${event.imageUrl})`;
    }

    return styles;
  }

}
