<div class="person-widget">
  <div class="person-widget--main" [ngClass]="getClasses()">
    <div class="person-widget-wrapper">
      <div *ngIf="widget.data.image" class="person-widget--image">
        <img [src]="getImageUrl()" [alt]="widget.data.name">
      </div>

      <div class="person-widget--info">
        <h3>{{widget.data.name}}</h3>
        <h6>{{widget.data.position}}</h6>

        <p class="person--biography" *ngIf="widget.data.biography">{{widget.data.biography}}</p>

        <hr *ngIf="widget.data.email || widget.data.phone || widget.data.linkText">

        <div class="person-widget--info-email" *ngIf="widget.data.email">{{widget.data.email}}</div>
        <div class="person-widget--info-phone" *ngIf="widget.data.phone">{{widget.data.phone}}</div>
        <div class="person-widget--info-link" *ngIf="widget.data.linkUrl">
          <a [routerLink]="widget.data.linkUrl">{{widget.data.linkText}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
