import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Accordion} from "./types/accordion";
import {AccordionModule} from "primeng/accordion";
import {ColumnComponent} from "../row/column/column.component";
import {AccordionSectionComponent} from "./accordion-section/accordion-section.component";

@Component({
  selector: 'app-layout-accordion',
  standalone: true,
  imports: [CommonModule, AccordionModule, ColumnComponent, AccordionSectionComponent],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.less']
})
export class AccordionComponent {
  @Input() layout!: Accordion;

  public getActiveIndex(): number[] | number {
    return this.layout.data.allowMultiple ? [0] : 0;
  }
}
