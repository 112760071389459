import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Widget} from "../../../page/types/widget";
import {RowComponent} from "../../layout/row/row.component";
import {SliderComponent} from "../slider/slider.component";
import {TitleComponent} from "../title/title.component";
import {TextComponent} from "../text/text.component";
import {SeparatorComponent} from "../separator/separator.component";
import {PromoBlockComponent} from "../promo-block/promo-block.component";
import {ButtonComponent} from "../button/button.component";
import {ImageComponent} from "../image/image.component";
import {PersonComponent} from "../person/person.component";
import {MenuComponent} from "../menu/menu.component";
import {ListComponent} from "../list/list.component";
import {PersonPageComponent} from "../person-page/person-page.component";
import {VideoComponent} from "../video/video.component";
import {GalleryComponent} from "../gallery/gallery.component";
import {EventsCalendarComponent} from "../events-calendar/events-calendar.component";
import {UpcomingEventsComponent} from "../upcoming-events/upcoming-events.component";
import {BibliographyComponent} from "../bibliography/bibliography.component";
import {ShortArticleComponent} from "../short-article/short-article.component";
import {LinkBlockComponent} from "../link-block/link-block.component";
import {TimelineComponent} from "../timeline/timeline.component";
import {EstatesAndDonationsComponent} from "../estates-and-donations/estates-and-donations.component";
import {PortraitComponent} from "../portrait/portrait.component";
import {PublicationsComponent} from "../publications/publications.component";
import {BlogComponent} from "../blog/blog.component";
import {DownloadLinkComponent} from "../download-link/download-link.component";
import {FormComponent} from "../form/form.component";
import {PlaylistComponent} from "../playlist/playlist.component";
import {MapComponent} from "../map/map.component";
import {ContactFormComponent} from "../contact-form/contact-form.component";
import {NewsletterComponent} from "../newsletter/newsletter.component";

@Component({
  selector: 'app-widget',
  standalone: true,
  imports: [CommonModule, RowComponent, SliderComponent, TitleComponent, TextComponent, SeparatorComponent, PromoBlockComponent, ButtonComponent, ImageComponent, PersonComponent, MenuComponent, ListComponent, PersonPageComponent, VideoComponent, GalleryComponent, EventsCalendarComponent, UpcomingEventsComponent, BibliographyComponent, ShortArticleComponent, LinkBlockComponent, TimelineComponent, EstatesAndDonationsComponent, PortraitComponent, PublicationsComponent, BlogComponent, DownloadLinkComponent, FormComponent, PlaylistComponent, MapComponent, ContactFormComponent, NewsletterComponent],
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.less']
})
export class WidgetComponent {
  @Input() widget!: Widget;
}
