<div class="timeline-widget" [ngClass]="getClasses()">
  <div class="timeline-widget--inner">
    <ng-container *ngFor="let item of widget.data.items">
      <div class="timeline--date">
        {{item.date}}
      </div>
      <div *ngIf="!widget.data.hideBullets" class="timeline--bullet"></div>
      <div class="timeline--content">
        <h5 *ngIf="item.title">{{item.title}}</h5>
        <div *ngIf="item.text" class="timeline-item--text" [innerHtml]="item.text"></div>
      </div>
    </ng-container>
  </div>
</div>
