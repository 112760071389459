import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonPage} from "./types/person-page";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-widget-person-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './person-page.component.html',
  styleUrls: ['./person-page.component.less']
})
export class PersonPageComponent {
  @Input() widget!: PersonPage;

  public getImageUrl(): string {
    return `${environment.cdn.url}/cdn/${this.widget.data.image}`;
  }

  public getClasses(): string[] {
    let classes = [
      `person-widget--background-${this.widget.data.style}`,
    ];

    if (this.widget.data.image === null) {
      classes.push('person-widget--no-image');
    }

    return classes;
  }

}
