<div class="newsletter-form-widget">
  <div class="newsletter-form--form" *ngIf="!formSubmitted">
    <form [formGroup]="form" (submit)="subscribe()">
      <div class="form-control">
        <label>
          <input placeholder="E-Mail" type="email" formControlName="email">
        </label>
      </div>
      <div class="form-control">
        <label>
          <input placeholder="Vorname" type="text" formControlName="firstName">
        </label>
      </div>
      <div class="form-control">
        <label>
          <input placeholder="Nachname" type="text" formControlName="lastName">
        </label>
      </div>
      <div class="form-control">
        <label>
          <span></span>
          <span class="checkbox">
            <input type="checkbox" formControlName="data">
            <span>Ich habe die Datenschutzbestimmung zur Kenntnis genommen</span>
          </span>
        </label>
      </div>
      <div class="form-control">
        <button class="button button-default">Newsletter bestellen</button>
      </div>
    </form>
  </div>

  <div *ngIf="formSubmitted">
    <p>
      Vielen Dank. Bitte bestätigen Sie Ihre Anmeldung über den Link, den wir Ihnen gleich per E-Mail zusenden. Bitte
      überprüfen Sie Ihren Spam-Filter, wenn Sie keine E-Mail erhalten
    </p>
  </div>
</div>
