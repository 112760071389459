import {Component, forwardRef, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WidgetComponent} from "../../../widget/widget/widget.component";
import {Section} from "../../../../page/types/section";
import {LayoutComponent} from "../../layout/layout.component";

@Component({
  selector: 'app-layout-row-column',
  standalone: true,
  imports: [CommonModule, WidgetComponent, forwardRef(() => LayoutComponent)],
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.less']
})
export class ColumnComponent {
  @Input() section!: Section;
}
