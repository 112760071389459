<ng-container *ngIf="event$ | async as event">
  <div class="event-details">
    <header class="event-header" [ngStyle]="getHeaderStyle(event)">
    </header>

    <div class="container container-boxed">
      <article class="event-details--page">
        <div class="event-details--content-top">
          <div class="title-and-subtitle">
            <h2>{{ event.name }}</h2>
            <h3>{{ event.subtitle }}</h3>
          </div>
          <div class="event-details--tickets">
            <div *ngIf="getEventPrices(event) as prices" class="event-details--price" [innerHtml]="prices"></div>
            <div *ngIf="event.ticketUrl" class="event-details--ticket-button">
              <a target="_blank" [href]="event.ticketUrl" class="button button-default">Karten kaufen</a>
            </div>
          </div>
        </div>

        <div class="event-details--info">
          <p class="event-details--date">
            <time>
              <strong>{{ event.startTime | date:'EEEE, dd. MMMM YYYY' }}</strong>
              | {{ event.startTime | date:'HH.mm' }} Uhr
            </time>
          </p>
          <p>{{ event.location }}</p>
        </div>

        <div class="separator separator-short"></div>

        <div *ngIf="event.artists" class="event-details--artists">
          <ul>
            <li *ngFor="let artist of event.artists" class="event-artist">
              <div class="event-artist--details">
                <span class="event-artist--name">{{ artist.name }}</span>
                <span *ngIf="artist.instrument" class="event-artist--instrument">{{ artist.instrument }}</span>
              </div>
              <div *ngIf="artist.isGroup" class="event-artist-group--members">
                <ul>
                  <li *ngFor="let member of artist.members">
                    <span class="event-artist--name">{{ member.name }}</span>
                    <span *ngIf="member.instrument" class="event-artist--instrument">{{ member.instrument }}</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div class="event-details--content" [innerHtml]="event.description">
        </div>

        <div class="event-details--sponsors">
          <h3>Sponsors of event</h3>
          <div class="event-sponsors--list">
            <div class="event-sponsor--details">
              <a target="_blank" href="assets/images/sponsors/sponsor-1.png">
                <img src="assets/images/sponsors/sponsor-1.png" alt="Sponsor 1"
                     title="Sponsor 1"/>
              </a>
            </div>
          </div>
        </div>

      </article>
    </div>
  </div>
</ng-container>
