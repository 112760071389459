<div class="person-page-widget">
  <div [ngClass]="getClasses()">
    <div class="person-widget-wrapper">
      <div>
        <div *ngIf="widget.data.image" class="person-widget--image">
          <img [src]="getImageUrl()" [alt]="widget.data.position">
        </div>

        <div class="person-widget--info">
          <h6>{{widget.data.position}}</h6>
          <hr *ngIf="widget.data.image === null">
          <div class="person-widget--info-email" *ngIf="widget.data.email">{{widget.data.email}}</div>
          <div class="person-widget--info-phone" *ngIf="widget.data.phone">{{widget.data.phone}}</div>
        </div>
      </div>
      <div>
        <div class="person-widget--biography" [innerHtml]="widget.data.biography"></div>
      </div>
    </div>
  </div>
</div>
