import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LinkBlock} from "./types/link-block";
import {RouterLink} from "@angular/router";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-widget-link-block',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './link-block.component.html',
  styleUrls: ['./link-block.component.less']
})
export class LinkBlockComponent {
  @Input() widget!: LinkBlock;

  public getImageUrl(): string {
    return `${environment.cdn.url}/cdn/${this.widget.data.image}`;
  }

  public getUrl(): string {
    if (this.widget.data.fileUrl) {
      return this.getFileUrl();
    }

    return this.widget.data.url;
  }

  public getFileUrl(): string {
    return `/assets/files/${this.widget.data.fileUrl}`;
  }

  public getClasses(): string[] {
    return [
      `text-${this.widget.data.textAlign ?? 'center'}`
    ]
  }
}
