<div class="video-widget" [ngClass]="getClasses()" [class.is-playing]="isPlaying">
  <div class="video-element" #videoContainer>
    <div class="thumbnail" (click)="playVideo()" [ngStyle]="getThumbnailStyle()">
    </div>
    <div class="player-wrapper">
      <youtube-player
        #player
        [videoId]="getVideoId()"
        (stateChange)="onPlayerStateChange($event)"
      ></youtube-player>
    </div>
  </div>
  <div class="video-description" [innerHtml]="widget.data.description">
  </div>
</div>
