<ng-container *ngIf="widget">
  <app-widget-slider *ngIf="widget.type === 'slider'" [widget]="widget"></app-widget-slider>
  <app-widget-title *ngIf="widget.type === 'title'" [widget]="widget"></app-widget-title>
  <app-widget-text *ngIf="widget.type === 'text'" [widget]="widget"></app-widget-text>
  <app-widget-separator *ngIf="widget.type === 'separator'" [widget]="widget"></app-widget-separator>
  <app-widget-promo-block *ngIf="widget.type === 'promo-block'" [widget]="widget"></app-widget-promo-block>
  <app-widget-button *ngIf="widget.type === 'button'" [widget]="widget"></app-widget-button>
  <app-widget-image *ngIf="widget.type === 'image'" [widget]="widget"></app-widget-image>
  <app-widget-person *ngIf="widget.type === 'person'" [widget]="widget"></app-widget-person>
  <app-widget-menu *ngIf="widget.type === 'menu'" [widget]="widget"></app-widget-menu>
  <app-widget-list *ngIf="widget.type === 'list'" [widget]="widget"></app-widget-list>
  <app-widget-person-page *ngIf="widget.type === 'person-page'" [widget]="widget"></app-widget-person-page>
  <app-widget-video *ngIf="widget.type === 'video'" [widget]="widget"></app-widget-video>
  <app-widget-gallery *ngIf="widget.type === 'gallery'" [widget]="widget"></app-widget-gallery>
  <app-widget-events-calendar *ngIf="widget.type === 'events-calendar'" [widget]="widget"></app-widget-events-calendar>
  <app-widget-upcoming-events *ngIf="widget.type === 'upcoming-events'" [widget]="widget"></app-widget-upcoming-events>
  <app-widget-bibliography *ngIf="widget.type === 'bibliography'"></app-widget-bibliography>
  <app-widget-short-article *ngIf="widget.type === 'short-article'" [widget]="widget"></app-widget-short-article>
  <app-widget-link-block *ngIf="widget.type === 'link-block'" [widget]="widget"></app-widget-link-block>
  <app-widget-timeline *ngIf="widget.type === 'timeline'" [widget]="widget"></app-widget-timeline>
  <app-widget-estates-and-donations *ngIf="widget.type === 'estates-and-donations'" [widget]="widget"></app-widget-estates-and-donations>
  <app-widget-portrait *ngIf="widget.type === 'portrait'" [widget]="widget"></app-widget-portrait>
  <app-widget-publications *ngIf="widget.type === 'publications'" [widget]="widget"></app-widget-publications>
  <app-widget-blog *ngIf="widget.type === 'blog'" [widget]="widget"></app-widget-blog>
  <app-widget-download-link *ngIf="widget.type === 'download-link'" [widget]="widget"></app-widget-download-link>
  <app-widget-form *ngIf="widget.type === 'form'" [widget]="widget"></app-widget-form>
  <app-widget-playlist *ngIf="widget.type === 'playlist'" [widget]="widget"></app-widget-playlist>
  <app-widget-map *ngIf="widget.type === 'map'" [widget]="widget"></app-widget-map>
  <app-widget-contact-form *ngIf="widget.type === 'contact-form'" [widget]="widget"></app-widget-contact-form>
  <app-widget-newsletter *ngIf="widget.type === 'newsletter'"></app-widget-newsletter>
</ng-container>
