<div class="estates-and-donations--filters">
  <form (submit)="onSearchSubmit()" [formGroup]="searchForm" class="form form-horizontal">
    <div class="form-control">
      <input type="text" [formControlName]="'query'" placeholder="Suche">
    </div>
    <div class="form-control">
      <button class="button button-default">Suche</button>
    </div>
  </form>
</div>

<div class="estates-and-donations">
  <ul class="list-bulleted" *ngIf="items.length">
    <li *ngFor="let item of items">
      <h6>{{item.lastName}}, {{item.firstName}} ({{item.from}} - {{item.to}})</h6>
      <div [innerHtml]="item.description"></div>
      <a *ngIf="item.file" [href]="item.file"></a>
    </li>
  </ul>
</div>
