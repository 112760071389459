import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactForm} from "./types/contact-form";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-widget-contact-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './contact-form.component.html',
  styleUrls: [
    './contact-form.component.less',
    '../../../../styles/form/form.less',
  ]
})
export class ContactFormComponent {
  @Input() widget!: ContactForm;
  public form: FormGroup;

  public constructor() {
    this.form = new FormGroup({
      recipient: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl(''),
      message: new FormControl(''),
      agb: new FormControl(false),
      data: new FormControl(false),
    });
  }
}
