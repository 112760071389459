<div class="contact-form-widget">
  <div class="contact-form--form">
    <form [formGroup]="form">
      <div class="form-control">
        <label>
          <span>Empfänger</span>
          <input type="text" formControlName="recipient">
        </label>
      </div>
      <div class="form-control">
        <label>
          <span>Name</span>
          <input type="text" formControlName="lastName">
        </label>
      </div>
      <div class="form-control">
        <label>
          <span>Vorname</span>
          <input type="text" formControlName="firstName">
        </label>
      </div>
      <div class="form-control">
        <label>
          <span>E-Mail-Adresse</span>
          <input type="email" formControlName="email">
        </label>
      </div>
      <div class="form-control">
        <label>
          <span>Nachricht</span>
          <textarea formControlName="message"></textarea>
        </label>
      </div>
      <div class="form-control">
        <label>
          <span></span>
          <span class="checkbox">
            <input type="checkbox" formControlName="agb">
            <span>Ich habe die AGB gelesen</span>
          </span>
        </label>
      </div>
      <div class="form-control">
        <label>
          <span></span>
          <span class="checkbox">
            <input type="checkbox" formControlName="data">
            <span>Ich stimme der Speicherung meiner Daten zu</span>
          </span>
        </label>
      </div>
      <div class="form-control">
        <button class="button button-default">Abschicken</button>
      </div>
    </form>
  </div>
  <div class="contact-form--address">
    <div class="address-icon-row">
      <div class="address-icon-row--icon">
        <img src="assets/images/icons/contact-home.svg" alt="Address">
      </div>
      <div class="address-icon-row--data">
        <span>{{widget.data.name}}</span>
        <span>{{widget.data.line1}}</span>
        <span>{{widget.data.line2}}</span>
        <span>{{widget.data.street}}</span>
        <span>{{widget.data.postalCode}} {{widget.data.town}}</span>
      </div>
    </div>
    <div class="address-icon-row">
      <div class="address-icon-row--icon">
        <img src="assets/images/icons/contact-phone.svg" alt="Phone">
      </div>
      <div class="address-icon-row--data">
        <span>{{widget.data.phone}}</span>
      </div>
    </div>
  </div>
</div>
