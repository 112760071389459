import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Portrait} from "./types/portrait";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-widget-portrait',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './portrait.component.html',
  styleUrls: ['./portrait.component.less']
})
export class PortraitComponent {
  @Input() widget!: Portrait;
  public modalActive: boolean = false;

  public getImageUrl(): string {
    return `${environment.cdn.url}/cdn/${this.widget.data.image}`
  }

  public openModal(): void {
    this.modalActive = true;
  }

  public closeModal(): void {
    this.modalActive = false;
  }
}
